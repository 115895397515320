// Make sure each route has an ending slash, otherwise links will not receive active css class
// More info https://github.com/gatsbyjs/gatsby/issues/10586
const routes = {
  ROOT: '/',
  COOKIES_POLICY: '/cookies-policy/',
  PRIVACY_POLICY: '/privacy-policy/',
  ABOUT_US: '/about-us/',
  CAREERS: '/careers/',
  RESOURCES: '/resources/',
  CASE_STUDIES: '/case-studies/',
  CASE_STUDIES_ZIGZAG: '/case-studies/zigzag/',
  CASE_STUDIES_ROBN: '/case-studies/robn/',
  CASE_STUDIES_SAMSARA: '/case-studies/samsara/',
  CASE_STUDIES_PRACTI: '/case-studies/practi/',
  CASE_STUDIES_HIPPO: '/case-studies/hippo/',
  CASE_STUDIES_OPTION_DRIVER: '/case-studies/option-driver/',
  CASE_STUDIES_UPKEEP: '/case-studies/upkeep/',
  CASE_STUDIES_LANTERN: '/case-studies/lantern/',
  ESTIMATE_PROJECT: '/estimate-project/',
  EU: '/eu/',
  INTERNSHIP_RECRUITMENT: '/mastaz-signup/',
  INTERNSHIP_LEVER:
    'https://jobs.lever.co/swingdev/de04f567-d760-404f-8f2b-522e372f191a',
  INTERNSHIP: '/mastaz/',
};

export default routes;
