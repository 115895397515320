import * as Sentry from '@sentry/browser';

import {
  COMMIT_HASH,
  PROD_ENV,
  SENTRY_DSN,
  SENTRY_ENV_NAME,
} from '~/constants';

class SentryService {
  initializeSentry() {
    Sentry.init({
      release: `swg-web#${COMMIT_HASH}`,
      dsn: SENTRY_DSN,
      enabled: PROD_ENV,
      environment: SENTRY_ENV_NAME,
    });

    Sentry.configureScope((scope: Sentry.Scope) => {
      scope.setTag('app', 'client');
    });
  }
}

const instance = new SentryService();
export { instance as SentryService };
