import { SentryService } from './src/services/Sentry';

export const onClientEntry = () => {
  SentryService.initializeSentry();
};

export const onRouteUpdate = ({ location: { pathname } }) => {
  // Track page visits with Facebook Pixel
  typeof fbq === 'function' && fbq('track', `Visited ${pathname}`);
};
