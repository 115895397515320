import routes from '~/routes';

import { LinkItem } from './types';

export const MAILCHIMP_USERID = '670d6046e29b20038cc70c152';
export const MAILCHIMP_INTERNSHIP_SIGNUP_ID = '4c1580e17b';
export const MAILCHIMP_INTERNSHIP_SIGNUP_URL =
  'https://swing3d.us16.list-manage.com/subscribe/post';
export const MAILCHIMP_INTERNSHIP_EMAILFIELD_ID = 'MERGE0';

export const PROD_ENV = process.env.NODE_ENV === 'production';
export const DEBUG = process.env.DEBUG as string;

export const SENTRY_DSN = process.env.SENTRY_DSN as string;
export const SENTRY_ENV_NAME = process.env.SENTRY_ENV_NAME as string;
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID as string;
export const CONTENTFUL_SPACE_ID = process.env.CONTENTFUL_SPACE_ID as string;
export const CONTENTFUL_TOKEN = process.env.CONTENTFUL_TOKEN as string;
export const CONTENTFUL_ENV = process.env.CONTENTFUL_ENV as string;
export const WEBSITE_URL = process.env.WEBSITE_URL as string;
export const COMMIT_HASH = process.env.COMMIT_HASH as string;
export const ESTIMATION_ENDPOINT = process.env.ESTIMATION_ENDPOINT as string;
export const MASTAZ_SUBMIT_SOLUTION = process.env
  .MASTAZ_SUBMIT_SOLUTION as string;
export const INTERN_RECRUITMENT_NEWSLETTER_URL = process.env
  .INTERN_RECRUITMENT_NEWSLETTER_URL as string;

export const DRIFT_KEY_CAREERS = process.env.DRIFT_KEY_CAREERS as string;
export const JACO_KEY = process.env.JACO_KEY as string;
export const HUBSPOT_ID = process.env.HUBSPOT_ID as string;
export const FACEBOOK_PIXEL_ID = process.env.FACEBOOK_PIXEL_ID as string;

export const MEDIUM_URL = 'https://medium.com/swinginc';

export const STATE_SNAPSHOT_KEY = '__STATE_SNAPSHOT__';

export const CONTACT_EMAIL = 'talent@swing.dev';

export const META = {
  keywords: [
    'business',
    'silicon valley',
    'programming',
    'developers',
    'javascript',
    'node.js',
  ].join(', '),
  description:
    'op-notch developers, designers and business leaders modernizing home insurance industry. We do the software the best possible way.',
  title: 'SwingDev a Hippo company | Developed with a human touch',
  titleTemplate: 'SwingDev – %s',
  imgAlt: 'SwingDev',
};

export const NAVIGATION_HEIGHT = 88;

export const Breakpoint = {
  EXTRA_SMALL: 480,
  SMALL: 768,
  MEDIUM: 992,
  LARGE: 1200,
  EXTRA_LARGE: 1480,
};

export const DEFAULT_ERROR_NAME = 'Error';
export const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred';

export const YOUTUBE_VIDEO_ALLOW_OPTIONS: string[] = [
  'accelerometer',
  'autoplay',
  'encrypted-media',
  'gyroscope',
  'picture-in-picture',
];

export const PATH_STATIC = '/static';

export const ROOT_NODE = '___gatsby';

export const LINKS: LinkItem[] = [
  {
    label: 'About',
    route: routes.ROOT,
  },
  {
    label: 'Resources',
    route: routes.RESOURCES,
  },
  {
    label: 'Case Studies',
    route: routes.CASE_STUDIES,
  },
  {
    label: 'Careers',
    route: routes.CAREERS,
  },
];

export const ESTIMATION_LINK: LinkItem = {
  label: 'Estimate Project',
  route: routes.ESTIMATE_PROJECT,
};

export const POLICY_LINKS: LinkItem[] = [
  {
    label: 'Cookies Policy',
    route: routes.COOKIES_POLICY,
  },
  {
    label: 'Privacy Policy',
    route: routes.PRIVACY_POLICY,
  },
];
