module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"wSNeUZrNfFSV2dLbZC7zArPHTh5kKF7a","trackPage":true,"manualLoad":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SwingDev","short_name":"SwingDev","start_url":"./?utm_source=web_app_manifest","orientation":"portrait","background_color":"#ffffff","theme_color":"#f5f5f5","display":"standalone","icon":"static/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"8cfc3acf325b36b90780c879eb359db1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
